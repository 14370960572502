import { UseStatesReturn } from "@/components/SearchBar/help/useStates";

const handleChange = (states: UseStatesReturn) => {
  const { inputRef, setInput, setShadowInput } = states;
  const value = inputRef.current?.value || "";

  setInput(value);
  setShadowInput(value);
};

export default handleChange;
