import styles from "./searchBar.module.css";
import { FunctionComponent } from "react";
import SearchBarResults from "./Results";
import useStates from "@/components/SearchBar/help/useStates";
import useEffects from "@/components/SearchBar/help/useEffects";
import handleSubmit from "@/components/SearchBar/help/handleSubmit";
import handleChange from "@/components/SearchBar/help/handleChange";
import handleFocus from "@/components/SearchBar/help/handleFocus";
import handleKey from "@/components/SearchBar/help/handleKey";
import LogoLeft from "@/components/svg/LogoLeft";
import LogoRight from "@/components/svg/LogoRight";
import { useSelector } from "react-redux";
import { getSearchQuery } from "@/redux/search/suggest/selectors";

type Props = {
  inMenu?: boolean;
};

const SearchBar: FunctionComponent<Props> = ({ inMenu = false }) => {
  const defaultSearchQuery = useSelector(getSearchQuery);
  const states = useStates(defaultSearchQuery || "");
  const { focused, inputRef, shadowInput, searchQuery } = states;

  useEffects(states);

  return (
    <div className={styles.container} data-focused={focused} data-menu={inMenu}>
      <div className={styles.logo}>
        <div className={styles.start}>
          <LogoLeft inMenu={inMenu} />
        </div>

        <div className={styles.dynamic}>
          <form
            className={styles.form}
            onSubmit={(e) => handleSubmit(e, states)}
          >
            <div className={styles.border} />

            <div className={styles.content}>
              <input
                ref={inputRef}
                className={styles.input}
                placeholder="Zadajte lokalitu..."
                onChange={() => handleChange(states)}
                value={shadowInput || ""}
                onFocus={() => handleFocus(true, states)}
                onBlur={() => handleFocus(false, states)}
                onKeyUp={(e) => handleKey(e as any)}
              />
              <button type="submit" className={styles.button}>
                Hľadať
              </button>
            </div>
          </form>

          {focused && (searchQuery || shadowInput) && (
            <SearchBarResults
              query={searchQuery || shadowInput}
              inMenu={inMenu}
            />
          )}
        </div>

        <div className={styles.end}>
          <LogoRight inMenu={inMenu} />
        </div>
      </div>

      {!inMenu && (
        <h1 className={styles.subheading}>
          Agregátor slovenských developerských projektov
        </h1>
      )}
    </div>
  );
};

export default SearchBar;
