import { useRouter } from "next/router";
import { MutableRefObject, useRef, useState } from "react";
import debounceCallback from "@/utils/other/debounceCallback";
import { useDispatch } from "react-redux";
import { setActiveSuggestion, suggestLocality } from "@/redux/search/suggest";

const useStates = (defaultSearchQuery: string) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [input, setInput] = useState<string>("");
  const [shadowInput, setShadowInput] = useState<string>(defaultSearchQuery);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const d = debounceCallback(150);

  const setDebouncedSearchQuery = d((query: string) => {
    setSearchQuery(query);
    dispatch(
      suggestLocality({
        query,
        fetch: true,
      }),
    );
    dispatch(setActiveSuggestion(null));
  });

  const setDebouncedFocused = d((value: boolean) => {
    setFocused(value);
  });

  return {
    router,
    inputRef,
    input,
    setInput,
    shadowInput,
    setShadowInput,
    searchQuery,
    setSearchQuery,
    focused,
    setFocused,
    setDebouncedSearchQuery,
    setDebouncedFocused,
  };
};

export type UseStatesReturn = ReturnType<typeof useStates>;

export default useStates;
