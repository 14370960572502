import { FunctionComponent } from "react";
import styles from "./results.module.css";
import urlFromFilter from "@/utils/filter/urlFromFilter";
import useEffects from "@/components/SearchBar/Results/help/useEffects";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveSearchIndex,
  getSearchResults,
} from "@/redux/search/suggest/selectors";
import { setSearchSharedLoading } from "@/redux/search/shared";

type Props = {
  query: string;
  inMenu?: boolean;
};

const LEVELS = {
  country: "Štát",
  region: "Región",
  city: "Mesto",
  cadastral_area: "Katastrálne územie",
  district: "Okres",
  borough: "Mestská časť",
  street: "Ulica",
};

const SearchBarResults: FunctionComponent<Props> = ({
  query,
  inMenu = false,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(getSearchResults);
  const activeIndex = useSelector(getActiveSearchIndex);

  useEffects(query);

  return data && data.length ? (
    <div className={styles.container} data-menu={inMenu}>
      {data.map(({ address, name, level, extraInfo }, i: number) => (
        <a
          href={`/novostavby/${urlFromFilter(address, name)}`}
          key={name + address.city + address.region + level}
          onClick={() => dispatch(setSearchSharedLoading(true))}
        >
          <div
            className={styles.result}
            data-active={activeIndex !== null && activeIndex === i}
          >
            <div className={styles.title}>{name}</div>
            <div className={styles.subtitle}>
              {level === "country"
                ? LEVELS.country
                : `${(LEVELS as any)[level] || level}, ${
                    extraInfo ? `${extraInfo}, ` : ""
                  } ${address.country}`}
            </div>
          </div>
        </a>
      ))}
    </div>
  ) : (
    <div />
  );
};

export default SearchBarResults;
