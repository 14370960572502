import styles from "./logoLeft.module.css";
import { FunctionComponent } from "react";

type Props = {
  inMenu?: boolean;
};
const LogoLeft: FunctionComponent<Props> = ({ inMenu = false }) => {
  return (
    <div data-menu={inMenu} className={styles.container}>
      <svg
        width="298"
        height="137"
        viewBox="0 0 298 137"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.85 134V24.5H78.2V134H58.85ZM148.411 59H167.761V134H148.411V125.15C142.611 132.45 134.461 136.1 123.961 136.1C113.961 136.1 105.361 132.3 98.1605 124.7C91.0605 117 87.5105 107.6 87.5105 96.5C87.5105 85.4 91.0605 76.05 98.1605 68.45C105.361 60.75 113.961 56.9 123.961 56.9C134.461 56.9 142.611 60.55 148.411 67.85V59ZM112.711 111.8C116.611 115.7 121.561 117.65 127.561 117.65C133.561 117.65 138.511 115.7 142.411 111.8C146.411 107.8 148.411 102.7 148.411 96.5C148.411 90.3 146.411 85.25 142.411 81.35C138.511 77.35 133.561 75.35 127.561 75.35C121.561 75.35 116.611 77.35 112.711 81.35C108.811 85.25 106.861 90.3 106.861 96.5C106.861 102.7 108.811 107.8 112.711 111.8ZM223.707 77.6H206.757V108.8C206.757 111.4 207.407 113.3 208.707 114.5C210.007 115.7 211.907 116.4 214.407 116.6C216.907 116.7 220.007 116.65 223.707 116.45V134C210.407 135.5 201.007 134.25 195.507 130.25C190.107 126.25 187.407 119.1 187.407 108.8V77.6H174.357V59H187.407V43.85L206.757 38V59H223.707V77.6Z"
          fill="#FF945B"
        />
        <path
          d="M49.2 45.5C38.6 44.7 33.3 48.95 33.3 58.25V59H49.2V77.6H33.3V134H13.95V77.6H3.15V59H13.95V58.25C13.95 47.65 16.9 39.6 22.8 34.1C28.7 28.6 37.5 26.2 49.2 26.9V45.5Z"
          fill="#FF945B"
        />
        <path
          d="M258.4 115.85H291.7V134H232.45V121.1L264.1 77.15H233.95V59H290.2V71.9L258.4 115.85Z"
          fill="#00C2C4"
        />
      </svg>
    </div>
  );
};

export default LogoLeft;
