import { FormEvent } from "react";
import { UseStatesReturn } from "@/components/SearchBar/help/useStates";
import store from "@/redux/store";
import {
  getActiveSearchIndex,
  getSearchResults,
} from "@/redux/search/suggest/selectors";
import navigateToSearch from "@/components/SearchBar/help/navigateToSearch";
import pushEvent from "@/utils/other/pushEvent";
import { filterChanged } from "@/utils/dataLayer/events/filterChanged";

const handleSubmit = (
  e: FormEvent<HTMLFormElement>,
  states: UseStatesReturn,
) => {
  const { router } = states;
  const state = store.getState();
  const results = getSearchResults(state);
  const activeIndex = getActiveSearchIndex(state);
  const navigate = navigateToSearch(router);

  if (results) {
    const { address, name } = activeIndex ? results[activeIndex] : results[0];
    navigate(address, name);
    pushEvent(filterChanged({ trigger: "query" }));
  }

  e.preventDefault();
};

export default handleSubmit;
