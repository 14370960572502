import { NextRouter } from "next/router";
import urlFromFilter from "@/utils/filter/urlFromFilter";
import { Data } from "@/api/fetchSuggestions";
import store from "@/redux/store";
import { setSearchSharedLoading } from "@/redux/search/shared";

type Address = Data["addressSuggestions"][number]["address"];

const navigateToSearch =
  (router: NextRouter) => (address: Address, name: string) => {
    const url = `/novostavby/${urlFromFilter(address, name)}`;
    store.dispatch(setSearchSharedLoading(true));
    router.push(url);
  };

export default navigateToSearch;
