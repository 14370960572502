import { Maybe } from "@/types/general";
import { Settings } from "@/types/api/complex";
import store from "@/redux/store";
import { getSearchSharedPageObj } from "@/redux/search/shared/selectors";

export const getSearchLevelString = (
  settings: Maybe<Settings>,
  isRelated = false,
) => {
  if (isRelated) return "related";
  else {
    const s = store.getState();
    const page = getSearchSharedPageObj(s);
    const location = settings?.current.location;

    if (location?.street) return "street";
    else if (location?.city) return "city";
    else if (location?.cadastralArea) return "cadastralArea";
    else if (location?.region) return "region";
    else if (location?.country) return "country";
    else if (page.type === "homepage") return "homepage";
    else return "undefined";
  }
};
