import { Maybe } from "@/types/general";
import { Settings } from "@/types/api/complex";
import { DEFAULT_PROPERTY_FILTER } from "@/data/constants";
import { OfferTypeKeys } from "@/types/api/derived";

export const getOfferTypeString = (settings: Maybe<Settings>) =>
  (
    settings?.current.offerTypes ||
    (DEFAULT_PROPERTY_FILTER.offerType as OfferTypeKeys[])
  )
    .join("_")
    .toLowerCase();
