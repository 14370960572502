import { useEffect } from "react";
import { UseStatesReturn } from "@/components/SearchBar/help/useStates";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveSearchIndex,
  getSearchResults,
} from "@/redux/search/suggest/selectors";
import { setActiveSuggestion, suggestLocality } from "@/redux/search/suggest";

const useEffects = (states: UseStatesReturn) => {
  const dispatch = useDispatch();
  const {
    setDebouncedSearchQuery,
    setShadowInput,
    input,
    focused,
    shadowInput,
  } = states;
  const results = useSelector(getSearchResults);
  const activeIndex = useSelector(getActiveSearchIndex);

  useEffect(() => {
    setDebouncedSearchQuery(input);
  }, [input]);

  useEffect(() => {
    if (results && activeIndex !== null && activeIndex !== undefined) {
      setShadowInput(results[activeIndex].name);
    }
  }, [activeIndex]);

  useEffect(() => {
    dispatch(setActiveSuggestion(null));

    if (focused && shadowInput && results?.length === 0) {
      dispatch(
        suggestLocality({
          query: shadowInput,
          fetch: true,
        }),
      );
    }
  }, [focused]);
};

export default useEffects;
