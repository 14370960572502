import { FunctionComponent } from "react";
import styles from "./logoRight.module.css";

type Props = {
  inMenu?: boolean;
};
const LogoRight: FunctionComponent<Props> = ({ inMenu = false }) => {
  return (
    <div data-menu={inMenu} className={styles.container}>
      <svg
        width="157"
        height="137"
        viewBox="0 -56 157 137"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.55 0.9C50.75 0.9 57.5 3.65 62.8 9.15C68.2 14.65 70.9 22.25 70.9 31.95V78H51.55V34.35C51.55 29.35 50.2 25.55 47.5 22.95C44.8 20.25 41.2 18.9 36.7 18.9C31.7 18.9 27.7 20.45 24.7 23.55C21.7 26.65 20.2 31.3 20.2 37.5V78H0.850001V3H20.2V11.4C24.9 4.4 32.35 0.9 42.55 0.9ZM99.2723 48.45C101.872 57.85 108.922 62.55 120.422 62.55C127.822 62.55 133.422 60.05 137.222 55.05L152.822 64.05C145.422 74.75 134.522 80.1 120.122 80.1C107.722 80.1 97.7723 76.35 90.2723 68.85C82.7723 61.35 79.0223 51.9 79.0223 40.5C79.0223 29.2 82.7223 19.8 90.1223 12.3C97.5223 4.7 107.022 0.9 118.622 0.9C129.622 0.9 138.672 4.7 145.772 12.3C152.972 19.9 156.572 29.3 156.572 40.5C156.572 43 156.322 45.65 155.822 48.45H99.2723ZM98.9723 33.45H137.222C136.122 28.35 133.822 24.55 130.322 22.05C126.922 19.55 123.022 18.3 118.622 18.3C113.422 18.3 109.122 19.65 105.722 22.35C102.322 24.95 100.072 28.65 98.9723 33.45Z"
          fill="#00C2C4"
        />
      </svg>
    </div>
  );
};

export default LogoRight;
