type Obj = Record<string, any>;

const mapObjectWithout = (obj: Obj, blacklist: string[]) => {
  const res: Obj = {};

  for (const k in obj) {
    if (!blacklist.includes(k)) res[k] = obj[k];
  }

  return res;
};

export default mapObjectWithout;
