import {
  getActiveSearchIndex,
  getSearchResults,
} from "@/redux/search/suggest/selectors";
import { setActiveSuggestion } from "@/redux/search/suggest";
import store from "@/redux/store";

const handleKey = (e: KeyboardEvent) => {
  const state = store.getState();
  const results = getSearchResults(state);
  const activeIndex = getActiveSearchIndex(state);

  const code = e.code;
  const length = results?.length;

  if (length) {
    const currentActive =
      activeIndex !== null && activeIndex !== undefined ? activeIndex : -1;

    if (code === "ArrowDown" && currentActive < length - 1)
      store.dispatch(setActiveSuggestion(currentActive + 1));
    else if (code === "ArrowUp" && currentActive > 0)
      store.dispatch(setActiveSuggestion(currentActive - 1));
  }
};

export default handleKey;
