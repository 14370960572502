import { SortOptionKeys } from "@/types/api/derived";
import { SORT_TYPE_OBJECTS } from "@/types/api/constants";
import { Maybe } from "@/types/general";
import { Settings } from "@/types/api/complex";

const SORT_MAP: Record<SortOptionKeys, any[]> = {
  top: [SORT_TYPE_OBJECTS[0]],
  price_asc: [SORT_TYPE_OBJECTS[1]],
  price_desc: [SORT_TYPE_OBJECTS[2]],
};

const getSortArray = (settings: Maybe<Settings>) => {
  return SORT_MAP[settings?.current.sort || "top"];
};

export default getSortArray;
