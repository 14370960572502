import { Maybe } from "@/types/general";
import { Settings } from "@/types/api/complex";
import { DEFAULT_PROPERTY_FILTER } from "@/data/constants";
import { EstateTypeKeys } from "@/types/api/derived";

export const getEstateTypeString = (settings: Maybe<Settings>) =>
  (
    settings?.current.estateTypes ||
    (DEFAULT_PROPERTY_FILTER.type as EstateTypeKeys[])
  )
    .join("_")
    .toLowerCase();
