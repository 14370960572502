import { FunctionComponent } from "react";
import styles from "./loading.module.css";

const Loading: FunctionComponent<{ center?: boolean }> = ({
  center = false,
}) => (
  <div className={styles.loading}>
    <div className={styles.dots} data-center={center}>
      <div className={styles.dot}>
        <div className={styles.animation} />
      </div>
      <div className={styles.dot}>
        <div className={styles.animation} />
      </div>
      <div className={styles.dot}>
        <div className={styles.animation} />
      </div>
    </div>

    <div className={styles.background} />
  </div>
);

export default Loading;
