const mapObjectProps = (obj: Record<string, any>, match: string | string[]) => {
  const isString = typeof match === "string";
  const matcher = isString
    ? (v: string, m: string) => m === v
    : (v: string, m: string[]) => m.includes(v);

  return Object.entries(obj)
    .filter(([, v]) => matcher(v, match as string & string[]))
    .map(([k]) => k);
};

export default mapObjectProps;
