import { View } from "@/types/api/derived";
import { Maybe } from "@/types/general";
import { Settings } from "@/types/api/complex";
import { SearchType } from "@/redux/search/shared/types";

const VIEW_MAP: Record<View, string> = {
  projects: "square-projects",
  cards: "square",
  rows: "row",
  table: "table",
  map: "map",
};

export const getViewString = (settings: Maybe<Settings>, type: SearchType) => {
  const raw = settings?.current.view;

  if (type === "detail" && raw === "projects") return "square-detail";
  else if (raw) return VIEW_MAP[raw];
  else return VIEW_MAP.projects;
};
