import { ENVIRONMENT, LANG, SITE_ID } from "@/data/constants";
import {
  getSearchSharedFilters,
  getSearchSharedPage,
  getSearchSharedPageObj,
  getSearchSharedSettings,
  getSearchSharedType,
} from "@/redux/search/shared/selectors";
import store from "@/redux/store";
import { DISPOSITION_MAP_KEYS_URL } from "@/types/api/maps";
import { DISPOSITION_KEYS } from "@/types/api/constants";
import { getViewString } from "@/utils/dataLayer/getters/getViewString";
import { getSearchLevelString } from "@/utils/dataLayer/getters/getSearchLevelString";
import { getEstateTypeString } from "@/utils/dataLayer/getters/getEstateTypeString";
import { getOfferTypeString } from "@/utils/dataLayer/getters/getOfferTypeString";
import getSortArray from "@/utils/dataLayer/getters/getSortArray";

type Data = {
  trigger: any;
  changed?: any;
  status?: any;
};

export const filterChanged = ({
  trigger,
  changed = undefined,
  status = undefined,
}: Data) => {
  const s = store.getState();
  const searchType = getSearchSharedType(s);
  const pageObj = getSearchSharedPageObj(s);
  const page = getSearchSharedPage(s);
  const settings = getSearchSharedSettings(s);
  const filters = getSearchSharedFilters(s);
  const view = getViewString(settings, searchType);
  const location = settings?.current.location;
  const disposition = (settings?.current.dispositions || DISPOSITION_KEYS).map(
    (v) => DISPOSITION_MAP_KEYS_URL[v],
  );
  const areaHist = filters?.current.areaRange;
  const areaSettings = settings?.current.area;
  const areaRaw = {
    min: areaSettings?.min || areaHist?.min,
    max: areaSettings?.max || areaHist?.max,
  };
  const area = {
    min:
      areaRaw.min !== null && areaRaw.min !== undefined
        ? Math.round(areaRaw.min)
        : null,
    max:
      areaRaw.max !== null && areaRaw.max !== undefined
        ? Math.round(areaRaw.max)
        : null,
  };
  const priceHist = filters?.current.priceHist;
  const priceSettings = settings?.current.price;
  const priceRaw = {
    min: priceSettings?.min || priceHist?.[0]?.low,
    max: priceSettings?.max || priceHist?.[priceHist?.length - 1]?.high,
  };
  const price = {
    min:
      priceRaw.min !== null && priceRaw.min !== undefined
        ? Math.round(priceRaw.min)
        : null,
    max:
      priceRaw.max !== null && priceRaw.max !== undefined
        ? Math.round(priceRaw.max)
        : null,
  };
  const sort = getSortArray(settings);
  const offerType = getOfferTypeString(settings);
  const type = getEstateTypeString(settings);
  const level = getSearchLevelString(settings);
  const pageType = pageObj.type;
  const pageName = pageObj.title;

  return {
    event: "filterChanged",
    trigger,
    changed,
    status,
    level,
    filter: {
      searchType: "novostavby",
      offerType,
      type,
      price,
      area,
      disposition,
      country: location?.country,
      region: location?.region,
      district: undefined,
      city: location?.city,
      cadastralArea: location?.cadastralArea,
      borough: undefined,
      street: location?.street,
      sortOptions: sort,
      view,
      page,
    },
    page: {
      siteId: SITE_ID,
      environment: ENVIRONMENT,
      pageType,
      pageLanguage: LANG,
      pageName,
    },
  };
};
