import { useEffect } from "react";
import { suggestLocality } from "@/redux/search/suggest";
import { useDispatch } from "react-redux";

const useEffects = (query: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      suggestLocality({
        query,
        fetch: true,
      }),
    );
  }, [query]);
};

export default useEffects;
