import {
  DEFAULT_PROPERTY_FILTER,
  DEFAULT_ESTATE_TYPES,
} from "@/data/constants";
import stringToUrl from "../string/stringToUrl";
import mapObjectProps from "../object/mapObjectProps";
import { PropertyFilter, SortOptionInput } from "@/types/api/complex";
import { OFFER_TYPE_MAP_URLS_KEYS } from "@/types/api/maps";
import { DISPOSITION_MAP_KEYS_URL } from "@/types/api/maps";

import { Range } from "@/types/api/simple";
import { Maybe } from "@/types/general";
import { DispositionKeys } from "@/types/api/derived";

const rangeParams = (type: string, range: Maybe<Range>): string => {
  const params =
    (range?.min ? `&${type}Min=${Math.round(range.min)}` : "") +
    (range?.max ? `&${type}Max=${Math.round(range.max)}` : "");
  return params;
};
const dispositionParam = (disposition: DispositionKeys[]) => {
  return disposition.length
    ? "&disposition=" +
        disposition
          .map((k: DispositionKeys) => DISPOSITION_MAP_KEYS_URL[k])
          .join("_")
    : "";
};
const sortParam = (sortOptions: SortOptionInput[]) => {
  const sortName = sortOptions?.length
    ? Object.values(sortOptions[0]).join("_").toLocaleLowerCase()
    : "ppc_desc";

  if (sortOptions?.length)
    return sortName === "ppc_desc" ? "" : "&sort=" + sortName;
  return "";
};
const createFilterParams = (mergedFilter: PropertyFilter | any): string => {
  const { price, area, roomCount: disposition, sortOptions } = mergedFilter;
  const priceP = rangeParams("price", price);
  const areaP = rangeParams("area", area);
  const dispositionP = dispositionParam(disposition);
  const sortP = sortParam(sortOptions);

  return priceP + areaP + dispositionP + sortP;
};

const urlFromFilter = (filter: PropertyFilter, name: string) => {
  const mergedFilter = {
    ...DEFAULT_PROPERTY_FILTER,
    ...filter,
  };
  const offerType = mapObjectProps(
    OFFER_TYPE_MAP_URLS_KEYS,
    mergedFilter?.offerType || [],
  ).join("_");
  const type = mapObjectProps(
    DEFAULT_ESTATE_TYPES,
    mergedFilter?.type || [],
  ).join("_");
  const location = stringToUrl(name);
  const queryParams = [
    mergedFilter.country,
    mergedFilter.region,
    mergedFilter.district,
    mergedFilter.cadastralArea,
    mergedFilter.city,
  ]
    .filter((v) => !!v)
    .join("~");
  const filterParams = createFilterParams(mergedFilter);

  const query: string = new URLSearchParams({ query: queryParams }).toString();

  return `${offerType}/${type}/${location}?${query}${filterParams}`;
};

export default urlFromFilter;
